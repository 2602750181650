import { Form, Button, Radio, Space } from "antd";
import MyCard from "../utility/myCard/MyCard";
import MyRadioGroup from "../utility/myRadioGroup/MyRadioGroup";
import MyInput from "../utility/myInput/MyInput";
import { CollectionType, TYPE_DATA, TYPE_DATA_TYPE } from "../dtype/All";

interface CategoryFormProps {
  form: any;
  type: TYPE_DATA_TYPE;
  userData: CollectionType | undefined;
  onTypeChange: (e: any) => any;
  onFinish: (values: any) => Promise<void>;
  onReset: () => void;
}

const CategoryForm = (props: CategoryFormProps) => {
  return (
    <MyCard
      title="Add Category"
      style={{
        width: 500,
        margin: "50px auto",
      }}
    >
      <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
        <Form.Item
          label="Type"
          name="type"
          initialValue={TYPE_DATA.Spending}
          rules={[{ required: true }]}
        >
          <MyRadioGroup onChange={props.onTypeChange}>
            <Radio value={TYPE_DATA.Spending}>{TYPE_DATA.Spending}</Radio>
            <Radio value={TYPE_DATA.Income}>{TYPE_DATA.Income}</Radio>
            <Radio value={TYPE_DATA.Investment}>{TYPE_DATA.Investment}</Radio>
          </MyRadioGroup>
        </Form.Item>

        <Form.Item
          label="Category"
          name="category"
          rules={[{ required: true, message: "Please enter category" }]}
        >
          <MyInput
            type="text"
            placeholder="Enter Category"
            maxLength={30}
            minLength={1}
          />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button" onClick={props.onReset}>
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </MyCard>
  );
};

export default CategoryForm;
