import { Chart } from "react-google-charts";

interface BarChartComponentProps {
  barData: (string | number)[][];
}

export const options = {
  chart: {
    title: "Expense Activities",
    subtitle: "Income, Spending, Investment",
  },
};

const BarChartComponent = (props: BarChartComponentProps) => {
  return (
    <Chart
      chartType="BarChart"
      width="99%"
      height="400px"
      data={props.barData}
      options={options}
    />
  );
};

export default BarChartComponent;
