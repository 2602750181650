type CardType = "income" | "investment" | "spending" | "savings";

interface ThemeColors {
  [key: string]: {
    [key in CardType]: string;
  };
}

export const TYPE_COLOR_STATS_CARD: ThemeColors = {
  light: {
    income: "#b5f5ec",
    investment: "#ffffb8",
    spending: "#ffe7ba",
    savings: "#f4ffb8",
  },
  dark: {
    income: "#146262",
    investment: "#aa9514",
    spending: "#7c3118",
    savings: "#536d13",
  },
};

export const TYPE_COLOR_TAG = {
  income: "green",
  investment: "yellow",
  spending: "volcano",
};
