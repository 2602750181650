import { createContext, useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
  User,
  deleteUser,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import {
  getFirestore,
  setDoc,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import openNotification from "../components/openNotification/openNotification";
import {
  AnalyticPayload,
  AuthUser,
  CollectionType,
  FirebaseContextType,
} from "../components/dtype/All";
import { FAILED, SAVED, SENT, SUCCESS } from "../utils/messages";

interface FirebaseProviderProps {
  children: any;
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

const FirebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(FirebaseApp);
const firebaseAuth = getAuth(FirebaseApp);
const googleProvider = new GoogleAuthProvider();
const db = getFirestore(FirebaseApp);

const FirebaseContext = createContext<FirebaseContextType | null>(null);

export const useFirebase = () => useContext(FirebaseContext);

const COLLECTION_NAME = "mde";

export const FirebaseProvider = (props: FirebaseProviderProps) => {
  const [user, setUser] = useState<User>();
  const [isLogin, setLogin] = useState<boolean>(false);
  const [isUserExistInCollection, setUserExistInCollection] =
    useState<boolean>(false);
  const [userCollection, setUserCollection] = useState<CollectionType>();

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, async (user) => {
      if (user && user.emailVerified) {
        setUser(user);
      } else {
        setLogin(false);
      }
    });
  });

  useEffect(() => {
    checkUserExistInStore();
  }, [user]);

  // Start: Authentication Using Email and Passowrd
  const signupUsingEmailPassword = async ({
    name = "",
    email,
    password,
  }: AuthUser): Promise<string> => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );
      const user = userCredential.user;
      setUser(undefined);
      setLogin(false);

      try {
        await sendEmailVerification(user);

        const data: CollectionType = {
          transactions: [],
          categories: {
            spending: [],
            income: [],
            investment: [],
          },
          occasions: [],
          language: "en",
          currency: "INR",
          theme: "",
          name: name,
        };

        await saveData(user.uid, data);

        openNotification({
          type: "success",
          message: `Registered Successfully.`,
          description: `Please verify your email from ${email}`,
          duration: 0,
        });

        logAnalyticCustomeEvents({
          eventName: "signup",
          extraData: { email },
        });

        return SAVED;
      } catch (error) {
        console.error("Error sending email verification:", error);
        return FAILED;
      }
    } catch (error) {
      const errorCode = (error as any).code;

      openNotification({
        type: "error",
        message: `Something Went Wrong!!!`,
        description: `${
          errorCode === "auth/email-already-in-use"
            ? `Email already exists`
            : "Please try after sometime"
        }`,
      });

      return errorCode === "auth/email-already-in-use"
        ? "Email already exists."
        : "Error occurred. Please try again later.";
    }
  };

  const siginEmailPassword = async ({
    email,
    password,
  }: AuthUser): Promise<string> => {
    try {
      // Set session persistence
      await setPersistence(firebaseAuth, browserSessionPersistence);

      // Attempt to sign in with email and password
      const userCredential = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );
      const user: User = userCredential.user;

      if (!user.emailVerified) {
        setLogin(false);
        openNotification({
          type: "error",
          message: `Verify Your Email`,
          description: `Please verify your email from ${user.email}.`,
        });
        return FAILED;
      } else {
        // Retrieve user collection data
        const docRef = doc(db, COLLECTION_NAME, user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data: CollectionType = docSnap.data() as CollectionType;
          setUserCollection(data);
          setLogin(true);
          openNotification({
            type: "success",
            message: `Welcome ${data.name}`,
          });
        }

        // Log custom analytic events
        logAnalyticCustomeEvents({
          eventName: "signin",
          extraData: {
            email,
            message: "Login Success",
          },
        });

        return SUCCESS;
      }
    } catch (error: any) {
      // Handle all errors here
      const errorMessage = error.message;
      console.error("Error signing in:", errorMessage);

      openNotification({
        type: "error",
        message: `Something Went Wrong`,
        description: `Please provide the correct email and password, or you may not have created an account yet.`,
      });

      // Log failed login analytic events
      logAnalyticCustomeEvents({
        eventName: "signin",
        extraData: {
          email,
          message: "Login Failed",
        },
      });

      return FAILED;
    }
  };

  const resetPassword = async (email: string): Promise<string> => {
    try {
      await sendPasswordResetEmail(firebaseAuth, email);
      setLogin(false);
      openNotification({
        type: "success",
        message: `Password reset email sent!`,
        duration: 0,
      });
      return SENT;
    } catch (error) {
      const errorCode = (error as any).code;
      const errorMessage = (error as any).message;
      openNotification({
        type: "error",
        message: `Error sending password reset email!`,
      });
      console.error(
        "Error sending password reset email:",
        errorCode,
        errorMessage
      );
      return FAILED;
    }
  };

  // End: Authentication Using Email and Passowrd

  const signinUserWithGoogle = async () => {
    signInWithPopup(firebaseAuth, googleProvider)
      .then(async (result) => {
        const user: User = result.user;
        setLogin(true);
        openNotification({
          type: "success",
          message: `Welcome ${user.displayName}`,
        });
      })
      .catch((error) => {
        console.log(error);
        openNotification({
          type: "error",
          message: `Something Went Wrong`,
          description: "Please try to re-login",
        });
      });
  };

  const checkUserExistInStore = async () => {
    if (user) {
      const userId: string | undefined = user?.uid;
      const docRef = doc(db, COLLECTION_NAME, userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let data: CollectionType = docSnap.data() as CollectionType;
        setUserCollection(data);
      } else {
        const data: CollectionType = {
          transactions: [],
          categories: {
            spending: [],
            income: [],
            investment: [],
          },
          occasions: [],
          language: "en",
          currency: "INR",
          theme: "",
          name: user?.displayName || "",
        };
        saveData(userId, data);
      }
    }
    setUserExistInCollection(false);
  };

  const logout = () => {
    signOut(firebaseAuth)
      .then(() => {
        setUser(undefined);
        setLogin(false);
        openNotification({
          type: "success",
          message: `Good Bye, See you soon!!!`,
        });
      })
      .catch((error) => {
        openNotification({
          type: "error",
          message: `Something Went Wrong!!!`,
          description: "Try after sometime",
        });
      });
  };

  const saveData = async (userId: string, data: any, path?: string[]) => {
    try {
      if (!data) throw new Error("Invalid Data");

      if (!path) {
        await setDoc(doc(db, COLLECTION_NAME, userId), data);
      } else {
        await setDoc(doc(db, COLLECTION_NAME, userId, ...path), data);
      }

      return SAVED;
    } catch (error) {
      console.log(error);
      return FAILED;
    }
  };

  const getData = async (userId: string, path?: string[]) => {
    let docRef;
    if (!path) {
      docRef = doc(db, COLLECTION_NAME, userId);
    } else {
      docRef = doc(db, COLLECTION_NAME, userId, ...path);
    }

    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    }

    return undefined;
  };

  const deleteAccount = () => {
    if (user) {
      deleteUser(user)
        .then(async () => {
          await deleteDoc(doc(db, COLLECTION_NAME, user.uid));
          logAnalyticCustomeEvents({
            eventName: "deleteAccount",
            extraData: {
              email: user.email,
            },
          });
          window.location.reload();
        })
        .catch((error) => {
          openNotification({
            type: "error",
            message: `Something Went Wrong!!!`,
            description: "Try after sometime or Try after re-login.",
          });
        });
    }
  };

  const logAnalyticCustomeEvents = (payload: AnalyticPayload) => {
    if (payload.extraData) {
      logEvent(analytics, payload.eventName, {
        ...payload.extraData,
        platform: "web",
      });
    } else {
      logEvent(analytics, payload.eventName, { platform: "web" });
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        user,
        isLogin,
        isUserExistInCollection,
        userCollection,
        signinUserWithGoogle,
        logout,
        checkUserExistInStore,
        getData,
        saveData,
        deleteAccount,
        signupUsingEmailPassword,
        siginEmailPassword,
        resetPassword,
        logAnalyticCustomeEvents,
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
};
