import React from "react";
import { Card } from "antd";
import { CardProps } from "antd/lib/card";

interface MyCardProps extends CardProps {
  children: React.ReactNode;
}

const MyCard: React.FC<MyCardProps> = ({ children, ...props }) => {
  return (
    <Card
      {...props}
      bordered={false}
      style={{
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        ...props.style,
      }}
    >
      {children}
    </Card>
  );
};

export default MyCard;
