export const FILTER_OPTION_KEY = {
  all: "all",
  yearlyMonthly: "yearlyMonthly",
  yearly: "yearly",
  range: "range",
  occasion: "occasion",
};

export const FILTER_OPTIONS_LIST = [
  {
    key: FILTER_OPTION_KEY.all,
    label: "All",
  },
  {
    key: FILTER_OPTION_KEY.yearlyMonthly,
    label: "Yearly & Monthly",
  },
  {
    key: FILTER_OPTION_KEY.yearly,
    label: "Yearly",
  },
  {
    key: FILTER_OPTION_KEY.range,
    label: "Date Range",
  },
  {
    key: FILTER_OPTION_KEY.occasion,
    label: "Occasion",
  },
];
