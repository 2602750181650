import { Button, Form, Popconfirm, Space, Tag } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import MyCard from "../utility/myCard/MyCard";
import MyTable from "../utility/myTable/MyTable";
import FilterComponent from "../filter/FilterComponent";
import {
  CollectionType,
  FilterType,
  FirebaseContextType,
  OccasionMapType,
  OccasionType,
  Stats,
  TransactionType,
  TYPE_DATA,
} from "../dtype/All";
import { useFirebase } from "../../context/Firebase";
import { useEffect, useState } from "react";
import StatisticBanner from "../statistic/StatisticBanner";
import { TYPE_COLOR_TAG } from "../designSystem/ElementColor";
import moment from "moment";
import openNotification from "../openNotification/openNotification";
import {
  filter as _filter,
  groupBy,
  map as _map,
  mapValues,
  sumBy,
  forEach as _forEach,
  sortBy,
} from "lodash";
import { FILTER_OPTION_KEY } from "../filter/FiterOptions";

const Transactions = () => {
  const [form] = Form.useForm();
  const firebase: FirebaseContextType | null = useFirebase();
  const userId: string | undefined = firebase?.user?.uid;
  const [userData, setUserData] = useState<CollectionType>();
  const [data, setData] = useState<TransactionType[]>([]);
  const [currencyCode, setCurrencyCode] = useState<string>("");
  const [stats, setStats] = useState<Stats>();
  const [userDataBackup, setUserDataBackup] = useState<CollectionType>();
  const [appliedFilter, setAppliedFilter] = useState<FilterType | undefined>(
    undefined
  );
  const [occasionMap, setOccasionMap] = useState<OccasionMapType>({});

  useEffect(() => {
    if (userId) {
      getUserCollection(userId);
    }
  }, [userId, firebase]);

  const getUserCollection = async (userId: string) => {
    firebase?.getData(userId)?.then((result: any) => {
      setUserDataBackup(result);
      setUserData(result);
      setCurrencyCode(result.currency);
      prepareData(result);
      if (result.occasions) {
        const occasionObj = result.occasions.reduce(
          (acc: any, occasion: OccasionType) => {
            acc[occasion.id] = occasion.occasion;
            return acc;
          },
          {}
        );

        setOccasionMap(occasionObj);
      }
    });
  };

  const prepareData = (result: CollectionType, filter?: FilterType) => {
    if (filter) {
      let transactionList: TransactionType[] = result?.transactions || [];
      if (filter.filter === FILTER_OPTION_KEY.yearly) {
        const year = new Date(filter.selectedFilter).getFullYear();
        transactionList = _filter(
          transactionList,
          (item) => new Date(item.date).getFullYear() === year
        );
      } else if (filter.filter === FILTER_OPTION_KEY.yearlyMonthly) {
        const year = new Date(filter.selectedFilter).getFullYear();
        const month = new Date(filter.selectedFilter).getMonth();
        transactionList = _filter(
          transactionList,
          (item) =>
            new Date(item.date).getFullYear() === year &&
            new Date(item.date).getMonth() === month
        );
      } else if (filter.filter === FILTER_OPTION_KEY.range) {
        const start = new Date(filter.selectedFilter[0]);
        const end = new Date(filter.selectedFilter[1]);
        end.setDate(end.getDate() + 1);
        transactionList = _filter(
          transactionList,
          (item) => start <= new Date(item.date) && end >= new Date(item.date)
        );
      } else if (filter.filter === FILTER_OPTION_KEY.occasion) {
        transactionList = _filter(
          transactionList,
          (item) => item.occasionId === filter.selectedFilter
        );
      }
      setData(transactionList);
      generateTypeStats(transactionList);
    } else {
      setData(result?.transactions || []);
      generateTypeStats(result?.transactions || []);
    }
  };

  const generateTypeStats = (userTransactions: TransactionType[]) => {
    let transactionList: TransactionType[] = userTransactions || [];
    const grouped = groupBy(transactionList, "type");
    const result = mapValues(grouped, (items) =>
      sumBy(items, (item) => Number(item.amount))
    );
    const income = result?.Income || 0;
    const spending = result?.Spending || 0;
    const investment = result?.Investment || 0;
    const savings = income - (spending + investment);
    setStats({
      income,
      spending,
      investment,
      savings,
    });
  };

  const handleDelete = async (row: TransactionType) => {
    if (userData?.transactions && userId) {
      let arr = userData.transactions.filter((item) => item.id !== row.id);
      userData.transactions = arr;
      sortBy(userData.transactions, ["id"]);
      await firebase?.saveData(userId, userData);

      if (!appliedFilter) await getUserCollection(userId);
      else {
        const list = data.filter((item) => item.id !== row.id);
        setData(list);
        generateTypeStats(list);
      }
      openNotification({
        type: "success",
        message: "Transaction deleted!!!",
      });
    }
  };

  const applyFilter = (values: any) => {
    setAppliedFilter(values);
    if (values.filter === "all") {
      if (userId) getUserCollection(userId);
    } else {
      if (userData) prepareData(userData, values);
    }
  };

  const resetFilter = () => {
    if (userDataBackup) prepareData(userDataBackup);
    form.resetFields();
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type: string) => {
        let color =
          type === TYPE_DATA.Spending
            ? TYPE_COLOR_TAG.spending
            : type === TYPE_DATA.Income
            ? TYPE_COLOR_TAG.income
            : TYPE_COLOR_TAG.investment;
        return (
          <Tag color={color} key={type}>
            {type}
          </Tag>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a: TransactionType, b: TransactionType) =>
        moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Mode of payment",
      dataIndex: "modeOfPayment",
      key: "modeOfPayment",
    },
    {
      title: "Amout",
      dataIndex: "amount",
      key: "amount",
      render: (text: number) => `${currencyCode} ${text}`,
      sorter: (a: TransactionType, b: TransactionType) => a.amount - b.amount,
    },
    {
      title: "Occasion",
      dataIndex: "occasionId",
      key: "occasionId",
      render: (text: string) => occasionMap[text],
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: TransactionType) => (
        <Space size="middle">
          <Popconfirm
            title="Delete the transaction"
            description="Are you sure to delete this transaction?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDelete(record)}
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <FilterComponent
        form={form}
        occasionList={userData?.occasions || []}
        onFinish={applyFilter}
        onReset={resetFilter}
      />

      <div style={{ paddingTop: "30px" }}>
        <StatisticBanner stats={stats} currencyCode={currencyCode} />
      </div>
      <MyCard
        title="Transactions"
        style={{
          width: "100%",
          margin: "50px auto",
        }}
      >
        <MyTable columns={columns} dataSource={data} />
      </MyCard>
    </>
  );
};

export default Transactions;
