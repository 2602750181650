import React from "react";
import { Avatar, Layout, Tooltip } from "antd";
import { GlobalOutlined } from "@ant-design/icons";

const { Footer } = Layout;

const FooterLayout: React.FC = () => {
  return (
    <Footer style={{ textAlign: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a
          href="https://mydailyexpenses.com"
          target="_blank"
          style={{ marginRight: 20, display: "flex", alignItems: "center" }}
        >
          <GlobalOutlined style={{ marginRight: 8 }} />
          mydailyexpenses.com
        </a>
      </div>
    </Footer>
  );
};

export default FooterLayout;
