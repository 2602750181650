export const SPENDING_CATEGORIES = [
  "Bill",
  "Doctor",
  "Food",
  "Medicine",
  "Movie",
  "Shopping",
];
export const INCOME_CATEGORIES = ["Business", "Freelancing", "Salary"];
export const INVESTMENT_CATEGORIES = ["Mutual Fund", "Stock"];
