import React, { useEffect, useState } from "react";
import { Row, Col, Select, Tooltip, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import MyCard from "../utility/myCard/MyCard";
import MySelect from "../utility/mySelect/MySelect";
import BarChartComponent from "./BarChartComponent";
import PieChartComponent from "./PieChartComponent";
import {
  CollectionType,
  FirebaseContextType,
  Stats,
  TransactionType,
} from "../dtype/All";
import { useFirebase } from "../../context/Firebase";
import {
  filter,
  groupBy,
  map as _map,
  mapValues,
  sumBy,
  uniq,
  forEach as _forEach,
  range,
} from "lodash";
import StatisticBanner from "../statistic/StatisticBanner";
import MyModal from "../utility/myModal/MyModal";
import { useNavigate } from "react-router-dom";

const getAllMonths = () => [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const { Option } = Select;

const Dashboard: React.FC = () => {
  const firebase: FirebaseContextType | null = useFirebase();
  const userId: string | undefined = firebase?.user?.uid;
  const [userData, setUserData] = useState<CollectionType>();
  const [dropdown, setDropdown] = useState<string>("all");
  const [years, setYears] = useState<Number[]>([]);
  const [stats, setStats] = useState<Stats>();
  const [barData, setBarData] = useState<(string | number)[][]>();
  const [pieData, setPieData] = useState<(string | number)[][]>();
  const [currencyCode, setCurrencyCode] = useState<string>("");
  const [openBarModal, setOpenBarModal] = useState(false);
  const [openPieModal, setOpenPieModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      getUserCollection(userId);
    }
  }, [userId, firebase]);

  useEffect(() => {
    generateYears();
    generateTypeStats();
    generatePieChart();
    generateBarChart();
  }, [userData]);

  useEffect(() => {
    generateTypeStats();
    generatePieChart();
    generateBarChart();
  }, [dropdown]);

  const getUserCollection = async (userId: string) => {
    firebase?.getData(userId)?.then((result: any) => {
      setUserData(result);
      setCurrencyCode(result?.currency || "INR");
    });
  };

  const generateYears = () => {
    const transactionList: TransactionType[] = userData?.transactions || [];
    const years = transactionList.map((item: TransactionType) =>
      new Date(item.date).getFullYear()
    );
    const uniqueYears = uniq(years).sort((a, b) => b - a);
    setYears(uniqueYears);
  };

  const generateTypeStats = () => {
    let transactionList: TransactionType[] = userData?.transactions || [];
    if (dropdown !== "all") {
      transactionList = filter(
        transactionList,
        (item) => new Date(item.date).getFullYear() === parseInt(dropdown)
      );
    }
    const grouped = groupBy(transactionList, "type");
    const result = mapValues(grouped, (items) =>
      sumBy(items, (item) => Number(item.amount))
    );
    const income = result?.Income || 0;
    const spending = result?.Spending || 0;
    const investment = result?.Investment || 0;
    const savings = income - (spending + investment);
    setStats({
      income,
      spending,
      investment,
      savings,
    });
  };

  const generatePieChart = () => {
    let transactionList: TransactionType[] = userData?.transactions || [];
    if (dropdown !== "all") {
      transactionList = filter(
        transactionList,
        (item) => new Date(item.date).getFullYear() === parseInt(dropdown)
      );
    }

    const grouped = groupBy(transactionList, "category");
    const sums = _map(grouped, (items, category) => {
      return [category, sumBy(items, (item) => Number(item.amount))];
    });
    const result: (string | number)[][] = [["Category", "Amount"], ...sums];
    setPieData(result);
  };

  const generateBarChart = () => {
    let transactionList: TransactionType[] = userData?.transactions || [];
    if (dropdown !== "all") {
      transactionList = filter(
        transactionList,
        (item) => new Date(item.date).getFullYear() === parseInt(dropdown)
      );
      calculateBarChartData(transactionList, true);
    } else {
      calculateBarChartData(transactionList);
    }
  };

  const calculateBarChartData = (
    transactionList: TransactionType[] = [],
    isMonth: boolean = false
  ) => {
    if (isMonth) {
      let group = groupBy(transactionList, (transaction) => {
        return new Date(transaction.date).getMonth();
      });

      const allMonths = range(0, 12); // 0: January, 11: December
      const monthsWithData: any = {};

      allMonths.forEach((month) => {
        if (group[month]) {
          const groupedByType = groupBy(group[month], "type");
          monthsWithData[month] = mapValues(groupedByType, (typeGroup) => {
            return sumBy(typeGroup, (item) => Number(item.amount));
          });
        } else {
          monthsWithData[month] = {
            Income: 0,
            Spending: 0,
            Investment: 0,
          };
        }
      });
      convertDataToChartDataArray(monthsWithData, isMonth);
    } else {
      let group = groupBy(transactionList, (transaction) => {
        return new Date(transaction.date).getFullYear();
      });

      const result = mapValues(group, (yearGroup) => {
        const groupedByType = groupBy(yearGroup, "type");
        return mapValues(groupedByType, (typeGroup) => {
          return sumBy(typeGroup, (item) => Number(item.amount));
        });
      });

      convertDataToChartDataArray(result, isMonth);
    }
  };

  const convertDataToChartDataArray = (
    result: any,
    isMonth: boolean = false
  ) => {
    const types = ["Income", "Spending", "Investment"];
    let resultArray: (string | number)[][] = [];

    if (isMonth) {
      resultArray = [["Month", ...types]];
      const months: any = getAllMonths();

      Object.keys(result).forEach((month) => {
        const monthData = [months[month]];
        types.forEach((type) => {
          monthData.push(result[month][type] || 0);
        });
        resultArray.push(monthData);
      });
    } else {
      resultArray = [["Year", ...types]];

      Object.keys(result).forEach((year) => {
        const yearData = [year];
        types.forEach((type) => {
          yearData.push(result[year][type] || 0);
        });
        resultArray.push(yearData);
      });
    }

    setBarData(resultArray);
  };

  const dropdownHandler = (val: string) => {
    setDropdown(val);
  };

  return (
    <>
      {userData && userData?.transactions?.length > 0 ? (
        <>
          <Row>
            <Col md={3} offset={21}>
              <MySelect
                placeholder="Select a currency"
                defaultValue="all"
                onChange={dropdownHandler}
              >
                <Option value="all">All Time</Option>
                {years.map((value: Number, key) => {
                  return (
                    <Option value={value} key={key}>
                      {value.toString()}
                    </Option>
                  );
                })}
              </MySelect>
            </Col>
          </Row>
          <div style={{ paddingTop: "30px" }}>
            <StatisticBanner stats={stats} currencyCode={currencyCode} />
          </div>
          <div style={{ paddingTop: "30px" }}>
            <Row gutter={16}>
              <Col span={12}>
                <MyCard
                  extra={
                    <Tooltip title="Detailed View">
                      <EyeOutlined
                        onClick={() => setOpenBarModal(!openBarModal)}
                      />
                    </Tooltip>
                  }
                >
                  <BarChartComponent barData={barData || []} />
                </MyCard>
              </Col>
              <Col span={12}>
                <MyCard
                  extra={
                    <Tooltip title="Detailed View">
                      <EyeOutlined
                        onClick={() => setOpenPieModal(!openPieModal)}
                      />
                    </Tooltip>
                  }
                >
                  <PieChartComponent pieData={pieData || []} />
                </MyCard>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <MyCard style={{ width: "50%", margin: "30%" }}>
          <Button
            onClick={() => navigate("/mde-add-expenses")}
            style={{ width: "100%" }}
            type="primary"
          >
            Add An Expense
          </Button>
        </MyCard>
      )}

      {openBarModal && (
        <MyModal
          visible={openBarModal}
          centered
          onCancel={() => setOpenBarModal(false)}
          width="100%"
          showFooter={false}
        >
          <BarChartComponent barData={barData || []} />
        </MyModal>
      )}
      {openPieModal && (
        <MyModal
          visible={openPieModal}
          centered
          onCancel={() => setOpenPieModal(false)}
          showFooter={false}
          width="100%"
        >
          <PieChartComponent pieData={pieData || []} />
        </MyModal>
      )}
    </>
  );
};

export default Dashboard;
