import { Col, Row, Statistic, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Stats } from "../dtype/All";
import MyCard from "../utility/myCard/MyCard";
import { TYPE_COLOR_STATS_CARD } from "../designSystem/ElementColor";
import { useMyTheme } from "../../context/Theme";

interface StatisticBannerProps {
  stats: Stats | undefined;
  currencyCode: string;
}

const StatisticBanner = (props: StatisticBannerProps) => {
  const { stats, currencyCode } = props;
  const myTheme = useMyTheme();
  const currentTheme = myTheme?.theme || "light";
  return (
    <Row gutter={16}>
      <Col span={6}>
        <MyCard
          style={{
            backgroundColor: TYPE_COLOR_STATS_CARD[currentTheme].income,
            borderRadius: "4px",
          }}
        >
          <Statistic
            title="Total Income"
            value={stats?.income || 0}
            prefix={currencyCode}
          />
        </MyCard>
      </Col>
      <Col span={6}>
        <MyCard
          style={{
            backgroundColor: TYPE_COLOR_STATS_CARD[currentTheme].investment,
            borderRadius: "4px",
          }}
        >
          <Statistic
            title="Total Investments"
            value={stats?.investment || 0}
            prefix={currencyCode}
          />
        </MyCard>
      </Col>
      <Col span={6}>
        <MyCard
          style={{
            backgroundColor: TYPE_COLOR_STATS_CARD[currentTheme].spending,
            borderRadius: "4px",
          }}
        >
          <Statistic
            title="Total Spendings"
            value={stats?.spending || 0}
            prefix={currencyCode}
          />
        </MyCard>
      </Col>
      <Col span={6}>
        <MyCard
          style={{
            backgroundColor: TYPE_COLOR_STATS_CARD[currentTheme].savings,
            borderRadius: "4px",
          }}
        >
          <Statistic
            title="Total Savings"
            value={stats?.savings || 0}
            prefix={currencyCode}
            suffix={
              <Tooltip
                placement="top"
                title="Total Income - (Total Investments + Total Spendings)"
              >
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
        </MyCard>
      </Col>
    </Row>
  );
};

export default StatisticBanner;
