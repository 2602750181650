import { Divider, Flex, Tag } from "antd";
import { TYPE_DATA } from "../dtype/All";
import MyCard from "../utility/myCard/MyCard";
import {
  INCOME_CATEGORIES,
  INVESTMENT_CATEGORIES,
  SPENDING_CATEGORIES,
} from "./PredefinedCategories";

const CategoryPredefined = () => {
  return (
    <MyCard
      title="Predefined Categories"
      style={{
        width: 500,
        margin: "50px auto",
      }}
    >
      <Divider orientation="left">{TYPE_DATA.Income}</Divider>
      <Flex gap="4px 0" wrap>
        {INCOME_CATEGORIES.map((val, index) => {
          return (
            <Tag color="green" key={index}>
              {val}
            </Tag>
          );
        })}
      </Flex>
      <Divider orientation="left">{TYPE_DATA.Investment}</Divider>
      <Flex gap="4px 0" wrap>
        {INVESTMENT_CATEGORIES.map((val, index) => {
          return (
            <Tag color="yellow" key={index}>
              {val}
            </Tag>
          );
        })}
      </Flex>
      <Divider orientation="left">{TYPE_DATA.Spending}</Divider>
      <Flex gap="4px 0" wrap>
        {SPENDING_CATEGORIES.map((val, index) => {
          return (
            <Tag color="volcano" key={index}>
              {val}
            </Tag>
          );
        })}
      </Flex>
    </MyCard>
  );
};

export default CategoryPredefined;
