import { User } from "firebase/auth";

export type TYPE_DATA_TYPE = "Income" | "Spending" | "Investment";
export const TYPE_DATA = {
  Income: "Income",
  Spending: "Spending",
  Investment: "Investment",
};

export interface Stats {
  income: number;
  spending: number;
  investment: number;
  savings: number;
}

export interface LanguageDataType {
  code: string;
  name: string;
}

export interface CurrencyDataType {
  code: string;
  name: string;
}

export interface FirebaseContextType {
  user: User | undefined;
  isLogin: boolean;
  isUserExistInCollection: boolean;
  userCollection: CollectionType | undefined;
  signinUserWithGoogle: () => void;
  logout: () => void;
  checkUserExistInStore: () => void;
  getData: (uid: string, path?: string[]) => any;
  saveData: (userId: string, data: any, path?: string[]) => any;
  deleteAccount: () => void;
  signupUsingEmailPassword: (user: AuthUser) => void;
  siginEmailPassword: (user: AuthUser) => void;
  resetPassword: (email: string) => void;
  logAnalyticCustomeEvents: (payload: AnalyticPayload) => void;
}

export interface TransactionType {
  id: string;
  date: string;
  category: string;
  occasionId?: string;
  amount: number;
  note?: string;
  type: TYPE_DATA_TYPE;
  payment_mode: string[];
}

export interface CollectionType {
  transactions: TransactionType[];
  categories: {
    spending: string[];
    income: string[];
    investment: string[];
  };
  occasions: OccasionType[];
  language: string;
  currency: string;
  theme: string;
  name: string;
}

export interface CategoryType {
  key: string;
  type: string;
  category: string;
}

export type FITER_OPTIONS = "all" | "yearlyMonthly" | "yearly" | "range";

export interface FilterType {
  filter: FITER_OPTIONS;
  selectedFilter?: any;
}

export interface ThemeType {
  theme: string;
  setTheme: (val: string) => void;
}

export interface AuthUser {
  name?: string;
  email: string;
  password: string;
}

export interface AnalyticPayload {
  eventName: string;
  extraData?: object;
}

export interface OccasionType {
  id: string;
  occasion: string;
  occasionDate: string;
  note: string;
}

export interface OccasionMapType {
  [key: string]: string;
}
