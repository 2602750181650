import { Button, Form, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useFirebase } from "../../context/Firebase";
import {
  CollectionType,
  FirebaseContextType,
  LanguageDataType,
} from "../dtype/All";
import openNotification from "../openNotification/openNotification";
import MyCard from "../utility/myCard/MyCard";
import MySelect from "../utility/mySelect/MySelect";
import { LanguageData } from "./LanguageData";

const { Option } = Select;

const Language = () => {
  const [form] = Form.useForm();
  const firebase: FirebaseContextType | null = useFirebase();
  const userId: string | undefined = firebase?.user?.uid;
  const [userData, setUserData] = useState<CollectionType>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userId) {
      setLoading(true);
      getUserCollection(userId);
    }
  }, [userId, firebase]);

  const getUserCollection = async (userId: string) => {
    firebase?.getData(userId)?.then((result: any) => {
      setUserData(result);
      form.setFieldsValue({ language: result.language });
    });
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    if (userId && userData) {
      setLoading(true);
      try {
        userData.language = values.language;
        await firebase?.saveData(userId, userData);
        await getUserCollection(userId);
        openNotification({
          type: "success",
          message: "Hurray!!!",
          description: "Successfully saved your language",
        });
      } catch (error) {
        setLoading(false);
        openNotification({
          type: "error",
          message: "Something went wrong!!!",
          description: "Try relogin in",
        });
        console.error("Error adding document: ", error);
      }
    }
  };

  return (
    <MyCard title="Set Language">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Language"
          name="language"
          rules={[{ required: true, message: "Please select language!" }]}
        >
          <MySelect placeholder="Select a language">
            {LanguageData.map((data: LanguageDataType, key) => {
              return (
                <Option value={data.code} key={key}>
                  {data.name}
                </Option>
              );
            })}
          </MySelect>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Spin spinning={loading} fullscreen />
    </MyCard>
  );
};

export default Language;
