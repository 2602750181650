import { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import CategoryForm from "./CategoryForm";
import CategoryPredefined from "./CategoryPredefined";
import CategoryTable from "./CategoryTable";
import { Form } from "antd";
import { useFirebase } from "../../context/Firebase";
import {
  INCOME_CATEGORIES,
  INVESTMENT_CATEGORIES,
  SPENDING_CATEGORIES,
} from "./PredefinedCategories";
import {
  CategoryType,
  CollectionType,
  FirebaseContextType,
  TYPE_DATA,
  TYPE_DATA_TYPE,
} from "../dtype/All";
import openNotification from "../openNotification/openNotification";

const Category = () => {
  const [form] = Form.useForm();
  const [type, setType] = useState<TYPE_DATA_TYPE>(
    TYPE_DATA.Spending as TYPE_DATA_TYPE
  );
  const firebase: FirebaseContextType | null = useFirebase();
  const userId: string | undefined = firebase?.user?.uid;
  const [userData, setUserData] = useState<CollectionType>();
  const [categoryList, setCategoryList] = useState<CategoryType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userId) {
      setLoading(true);
      getUserCollection(userId);
    }
  }, [userId, firebase]);

  useEffect(() => {
    prepareCategoryListForTableView();
  }, [userData]);

  const prepareCategoryListForTableView = () => {
    let list: CategoryType[] = [];

    if (userData?.categories?.income) {
      const output = userData?.categories?.income.map((category) => ({
        type: TYPE_DATA.Income,
        category: category,
        key: category,
      }));
      list = [...output, ...list];
    }

    if (userData?.categories?.investment) {
      const output = userData?.categories?.investment.map((category) => ({
        type: TYPE_DATA.Investment,
        category: category,
        key: category,
      }));
      list = [...output, ...list];
    }

    if (userData?.categories?.spending) {
      const output = userData?.categories?.spending.map((category) => ({
        type: TYPE_DATA.Spending,
        category: category,
        key: category,
      }));
      list = [...list, ...output];
    }

    setCategoryList(list);
  };

  const getUserCollection = (userId: string) => {
    firebase?.getData(userId)?.then((result: any) => {
      setUserData(result);
    });
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    if (!userId) {
      openNotification({
        type: "error",
        message: "Something went wrong!!!",
        description: "Try relogin in",
      });
      return;
    }

    const toBeInsert = values.category;
    const type = values.type;

    if (
      SPENDING_CATEGORIES.includes(toBeInsert) ||
      INCOME_CATEGORIES.includes(toBeInsert) ||
      INVESTMENT_CATEGORIES.includes(toBeInsert)
    ) {
      openNotification({
        type: "error",
        message: "Invalid Category!!!",
        description: "Category already predefined",
      });
      return;
    }

    if (
      type === TYPE_DATA.Spending ||
      type === TYPE_DATA.Income ||
      type === TYPE_DATA.Investment
    ) {
      if (userData?.categories?.spending?.includes(toBeInsert)) {
        openNotification({
          type: "error",
          message: "Invalid Category!!!",
          description: `Category already present in ${TYPE_DATA.Spending} type`,
        });
        return;
      }

      if (userData?.categories?.investment?.includes(toBeInsert)) {
        openNotification({
          type: "error",
          message: "Invalid Category!!!",
          description: `Category already present in ${TYPE_DATA.Investment} type`,
        });
        return;
      }

      if (userData?.categories?.income?.includes(toBeInsert)) {
        openNotification({
          type: "error",
          message: "Invalid Category!!!",
          description: `Category already present in ${TYPE_DATA.Income} type`,
        });
        return;
      }
    }

    if (type === TYPE_DATA.Spending) {
      userData?.categories?.spending.push(toBeInsert);
      userData?.categories?.spending.sort();
    } else if (type === TYPE_DATA.Investment) {
      userData?.categories?.investment.push(toBeInsert);
      userData?.categories?.investment.sort();
    } else {
      userData?.categories?.income.push(toBeInsert);
      userData?.categories?.income.sort();
    }

    setLoading(true);

    try {
      await firebase?.saveData(userId, userData);
      await getUserCollection(userId);
      resetForm();
      openNotification({
        type: "success",
        message: "Hurray!!!",
        description: "Successfully saved your expenses",
      });
    } catch (error) {
      setLoading(false);
      openNotification({
        type: "error",
        message: "Something went wrong!!!",
        description: "Try relogin in",
      });
      console.error("Error adding document: ", error);
    }
  };

  const resetForm = () => {
    setType(TYPE_DATA.Spending as TYPE_DATA_TYPE);
    form.resetFields();
  };

  const onTypeChange = (e: any) => {
    setType(e.target.value);
  };

  const handleDelete = async (category: CategoryType) => {
    if (userData && userId) {
      if (category.type === TYPE_DATA.Spending) {
        let data = userData?.categories?.spending || [];
        userData.categories.spending = data.filter(
          (cat) => cat !== category.key
        );
      }

      if (category.type === TYPE_DATA.Investment) {
        let data = userData?.categories?.investment || [];
        userData.categories.investment = data.filter(
          (cat) => cat !== category.key
        );
      }

      if (category.type === TYPE_DATA.Income) {
        let data = userData?.categories?.income || [];
        userData.categories.income = data.filter((cat) => cat !== category.key);
      }

      console.log("userData", userData);

      await firebase?.saveData(userId, userData);
      await getUserCollection(userId);

      openNotification({
        type: "success",
        message: "Category deleted!!!",
      });
    }
  };

  return (
    <Row>
      <Col md={10}>
        <CategoryForm
          form={form}
          type={type}
          userData={userData}
          onTypeChange={onTypeChange}
          onFinish={onFinish}
          onReset={resetForm}
        />
        <CategoryPredefined />
      </Col>
      <Col md={14}>
        <CategoryTable categories={categoryList} handleDelete={handleDelete} />
      </Col>
      <Spin spinning={loading} fullscreen />
    </Row>
  );
};

export default Category;
