import { Form, Button, Space } from "antd";
import MyCard from "../utility/myCard/MyCard";
import MyInput from "../utility/myInput/MyInput";
import { CollectionType } from "../dtype/All";
import MyDatePicker from "../utility/myDatePicker/MyDatePicker";

interface OccasionsFormProps {
  form: any;
  userData: CollectionType | undefined;
  onTypeChange: (e: any) => any;
  onFinish: (values: any) => Promise<void>;
  onReset: () => void;
}

const OccasionsForm = (props: OccasionsFormProps) => {
  return (
    <MyCard
      title="Add Occasion"
      style={{
        width: 500,
        margin: "50px auto",
      }}
    >
      <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
        <Form.Item
          label="Occasion"
          name="occasion"
          rules={[{ required: true, message: "Please enter occasion name" }]}
        >
          <MyInput
            type="text"
            placeholder="Enter Occasion"
            maxLength={30}
            minLength={1}
          />
        </Form.Item>
        <Form.Item
          label="Occasion Date"
          name="occasionDate"
          rules={[{ required: true, message: "Please select occasion date!" }]}
        >
          <MyDatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
        </Form.Item>

        <Form.Item
          label="Notes"
          name="note"
          rules={[
            {
              pattern: /^(?!\s*$).+/,
              message: "Note cannot be empty or just spaces",
            },
            {
              max: 30,
              message: "Note must be less than or equal to 30 characters",
            },
          ]}
        >
          <MyInput placeholder="Add any notes here" maxLength={30} />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button" onClick={props.onReset}>
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </MyCard>
  );
};

export default OccasionsForm;
