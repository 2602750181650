import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { Form } from "antd";
import ExpenseForm from "./ExpenseForm";
import ExpenseTable from "./ExpenseTable";
import { filter, sortBy } from "lodash";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import openNotification from "../openNotification/openNotification";
import {
  CollectionType,
  FirebaseContextType,
  OccasionMapType,
  OccasionType,
  TransactionType,
  TYPE_DATA,
  TYPE_DATA_TYPE,
} from "../dtype/All";
import { useFirebase } from "../../context/Firebase";

const Expenses = () => {
  const [form] = Form.useForm();
  const [type, setType] = useState<TYPE_DATA_TYPE>(
    TYPE_DATA.Spending as TYPE_DATA_TYPE
  );
  const firebase: FirebaseContextType | null = useFirebase();
  const userId: string | undefined = firebase?.user?.uid;
  const [userData, setUserData] = useState<CollectionType>();
  const [todaysTransactions, setTodaysTransactions] = useState<
    TransactionType[]
  >([]);
  const [selectedTransaction, setSelectedTransaction] =
    useState<TransactionType>();
  const [currencyCode, setCurrencyCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [occasionMap, setOccasionMap] = useState<OccasionMapType>({});

  useEffect(() => {
    if (userId) {
      setLoading(true);
      getUserCollection(userId);
    }
  }, [userId]);

  useEffect(() => {
    filterOutTodaysTransactions();
  }, [userData]);

  useEffect(() => {
    if (selectedTransaction) {
      form.setFieldsValue({
        ...selectedTransaction,
        date: moment(selectedTransaction.date),
      });
      setType(selectedTransaction.type);
    }
  }, [selectedTransaction, form]);

  const filterOutTodaysTransactions = () => {
    const today = moment().format("YYYY-MM-DD");
    const filteredData: TransactionType[] = filter(userData?.transactions, {
      date: today,
    });

    setTodaysTransactions(filteredData);
  };

  const getUserCollection = async (userId: string) => {
    const result = await firebase?.getData(userId);
    if (result) {
      setUserData(result);
      setCurrencyCode(result?.currency || "INR");

      if (result.occasions) {
        const occasionObj = result.occasions.reduce(
          (acc: any, occasion: OccasionType) => {
            acc[occasion.id] = occasion.occasion;
            return acc;
          },
          {}
        );

        setOccasionMap(occasionObj);
      }
    }
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    if (!userId) {
      openNotification({
        type: "error",
        message: "Something went wrong!!!",
        description: "Try relogin in",
      });
      return;
    }

    let formattedData: TransactionType;
    setLoading(true);

    if (selectedTransaction) {
      if (userData?.transactions) {
        let arr = userData.transactions.filter(
          (item) => item.id !== selectedTransaction.id
        );
        userData.transactions = arr;
        formattedData = {
          ...values,
          date: values.date.format("YYYY-MM-DD"),
          id: selectedTransaction.id,
        };

        if (!formattedData?.note) {
          delete formattedData.note;
        }
        userData?.transactions.push(formattedData);
      }
    } else {
      formattedData = {
        ...values,
        date: values.date.format("YYYY-MM-DD"),
        id: uuidv4(),
      };

      if (!formattedData?.note) {
        delete formattedData.note;
      }

      userData?.transactions.push(formattedData);
    }

    sortBy(userData?.transactions, ["id"]);

    try {
      const resp = await firebase?.saveData(userId, userData);
      await getUserCollection(userId);
      resetForm();
      openNotification({
        type: "success",
        message: "Hurray!!!",
        description: "Successfully saved your expenses",
      });
      firebase?.logAnalyticCustomeEvents({
        eventName: "add_expense",
        extraData: {
          userId,
        },
      });
      if (resp) setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        type: "error",
        message: "Something went wrong!!!",
        description: "Try relogin in",
      });
      console.error("Error adding document: ", error);
    }
  };

  const onTypeChange = (e: any) => {
    setType(e.target.value);
    form.resetFields(["category"]);
  };

  const resetForm = () => {
    setType(TYPE_DATA.Spending as TYPE_DATA_TYPE);
    form.resetFields();
    setSelectedTransaction(undefined);
  };

  const handleEdit = (id: string) => {
    if (userData?.transactions && userId) {
      let obj: TransactionType | undefined = userData.transactions.find(
        (item) => item.id === id
      );
      setSelectedTransaction(obj);
    }
  };

  const handleDelete = async (id: string) => {
    if (userData?.transactions && userId) {
      let arr = userData.transactions.filter((item) => item.id !== id);
      userData.transactions = arr;
      sortBy(userData.transactions, ["id"]);
      await firebase?.saveData(userId, userData);
      await getUserCollection(userId);

      openNotification({
        type: "success",
        message: "Transaction deleted!!!",
      });
    }
  };

  return (
    <>
      <Row>
        <Col md={10}>
          <ExpenseForm
            form={form}
            type={type}
            userData={userData}
            currencyCode={currencyCode}
            selectedTransaction={selectedTransaction}
            onTypeChange={onTypeChange}
            onFinish={onFinish}
            onReset={resetForm}
          />
        </Col>
        <Col md={14}>
          <ExpenseTable
            todaysTransactions={todaysTransactions}
            currencyCode={currencyCode}
            occasionMap={occasionMap}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </Col>
      </Row>
      <Spin spinning={loading} fullscreen />
    </>
  );
};

export default Expenses;
