import { Layout, Typography } from "antd";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const ContactUs = () => {
  return (
    <>
      <Layout className="layout">
        <Content style={{ padding: "0 50px", marginTop: "20px" }}>
          <div
            style={{
              padding: "24px",
              margin: "16px 0",
              textAlign: "center",
            }}
          >
            <Title level={2}>Contact Us</Title>
            <Paragraph>
              If you have any support requests, queries, suggestions, or
              technical issues, please email us at:
            </Paragraph>
            <Paragraph
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "#1890ff",
              }}
            >
              support@mydailyexpenses.com
            </Paragraph>
            <Paragraph>
              Please make sure to send the email from your registered email ID.
            </Paragraph>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default ContactUs;
