import { Layout, Typography } from "antd";
import DownloadAppButton from "../downloadAppButton/DownloadAppButton";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const AboutApp = () => {
  return (
    <>
      <Layout className="layout">
        <Content style={{ padding: "0 50px", marginTop: "20px" }}>
          <div
            style={{
              padding: "24px",
              margin: "16px 0",
              textAlign: "justify",
            }}
          >
            <Title level={2}>About MDE</Title>
            <Paragraph>
              Manage your finances effortlessly with My Daily Expenses (MDE),
              the ultimate personal finance app designed to help you track every
              penny spent. Whether it's monitoring your daily spendings,
              incomes, or investments. MDE is not an organization or company. It
              was developed by a sole developer for personal use and is a
              non-profit application. For more details, you can visit{" "}
              <a href="https://mydailyexpenses.com/" target="_blank">
                mydailyexpenses.com
              </a>
              .
            </Paragraph>
            <Paragraph>
              You can also use our Android app, which you can download from the
              link below. Note: We are not on the Google Play Store, as this is
              a non-profit application, and we are trying to avoid the
              investment required for Google's '20 testers policy.'
              Additionally, we are not on the Apple Store because we currently
              do not want to spend a large amount on Apple devices and Apple
              Store registration. Apple users can use the mobile web view on
              their devices. If we receive a good response, we will consider
              publishing this app on both the Google Play Store and the Apple
              Store.
            </Paragraph>
          </div>
          <div style={{ textAlign: "center" }}>
            <DownloadAppButton />
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default AboutApp;
