import React from "react";
import { Modal, ModalProps } from "antd";
import "antd/dist/reset.css"; // Ensure Ant Design styles are included

// Define the type for the props
interface CustomModalProps
  extends Omit<ModalProps, "visible" | "onOk" | "onCancel"> {
  visible: boolean;
  title?: React.ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
  width?: number | string;
  centered?: boolean;
  children?: React.ReactNode;
  showFooter?: boolean;
}

const MyModal: React.FC<CustomModalProps> = ({
  visible,
  title,
  onOk,
  onCancel,
  okText,
  cancelText,
  width,
  centered,
  children,
  showFooter = true,
  ...rest
}) => {
  return (
    <Modal
      visible={visible}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      width={width}
      centered={centered}
      footer={showFooter ? undefined : null}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default MyModal;
