import { Form, Button, Radio, Select, Space, Alert, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import MyCard from "../utility/myCard/MyCard";
import MySelect from "../utility/mySelect/MySelect";
import MyRadioGroup from "../utility/myRadioGroup/MyRadioGroup";
import MyDatePicker from "../utility/myDatePicker/MyDatePicker";
import MyInputNumber from "../utility/myInputNumber/MyInputNumber";
import {
  INCOME_CATEGORIES,
  INVESTMENT_CATEGORIES,
  SPENDING_CATEGORIES,
} from "../category/PredefinedCategories";
import {
  CollectionType,
  OccasionType,
  TransactionType,
  TYPE_DATA,
  TYPE_DATA_TYPE,
} from "../dtype/All";
import { PAYMENT_OPTIONS } from "./PaymentOptions";
import MyInput from "../utility/myInput/MyInput";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const { Text, Link } = Typography;

interface ExpenseFormProps {
  form: any;
  type: TYPE_DATA_TYPE;
  userData: CollectionType | undefined;
  selectedTransaction: TransactionType | undefined;
  currencyCode: string;
  onTypeChange: (e: any) => any;
  onFinish: (values: any) => Promise<void>;
  onReset: () => void;
}

const ExpenseForm = (props: ExpenseFormProps) => {
  const { onReset, selectedTransaction, currencyCode } = props;
  const navigate = useNavigate();

  const prepareOptions = () => {
    if (props.type === TYPE_DATA.Spending) {
      let arr: string[];
      if (props.userData?.categories?.spending) {
        arr = [...SPENDING_CATEGORIES, ...props.userData?.categories?.spending];
      } else {
        arr = [...SPENDING_CATEGORIES];
      }
      return arr.map((val, key) => (
        <Option value={val} key={key}>
          {val}
        </Option>
      ));
    } else if (props.type === TYPE_DATA.Income) {
      let arr: string[];
      if (props.userData?.categories?.income) {
        arr = [...INCOME_CATEGORIES, ...props.userData?.categories?.income];
      } else {
        arr = [...INCOME_CATEGORIES];
      }
      return arr.map((val, key) => (
        <Option value={val} key={key}>
          {val}
        </Option>
      ));
    } else {
      let arr: string[];
      if (props.userData?.categories.investment) {
        arr = [
          ...INVESTMENT_CATEGORIES,
          ...props.userData?.categories.investment,
        ];
      } else {
        arr = [...INVESTMENT_CATEGORIES];
      }
      return arr.map((val, key) => (
        <Option value={val} key={key}>
          {val}
        </Option>
      ));
    }
  };

  return (
    <MyCard
      title="Add Expense"
      style={{
        width: 500,
        margin: "50px auto",
      }}
    >
      <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
        <Form.Item
          label="Type"
          name="type"
          initialValue={TYPE_DATA.Spending}
          rules={[{ required: true }]}
        >
          <MyRadioGroup onChange={props.onTypeChange}>
            <Radio value={TYPE_DATA.Spending}>{TYPE_DATA.Spending}</Radio>
            <Radio value={TYPE_DATA.Income}>{TYPE_DATA.Income}</Radio>
            <Radio value={TYPE_DATA.Investment}>{TYPE_DATA.Investment}</Radio>
          </MyRadioGroup>
        </Form.Item>

        <Form.Item
          label="Category"
          name="category"
          rules={[{ required: true, message: "Please select a category!" }]}
        >
          <MySelect placeholder="Select a category">
            {prepareOptions()}
          </MySelect>
        </Form.Item>

        <Form.Item
          label="Date"
          name="date"
          rules={[{ required: true, message: "Please select a date!" }]}
        >
          <MyDatePicker
            style={{ width: "100%" }}
            format="YYYY-MM-DD"
            disabled={selectedTransaction ? true : false}
          />
        </Form.Item>

        <Form.Item
          label="Mode of Payment"
          name="modeOfPayment"
          rules={[
            { required: true, message: "Please select a mode of payment!" },
          ]}
        >
          <MySelect placeholder="Select a mode of payment">
            {PAYMENT_OPTIONS.map((value, key) => {
              return (
                <Option value={value.value} key={key}>
                  {value.label}
                </Option>
              );
            })}
          </MySelect>
        </Form.Item>

        <Form.Item
          label="Amount"
          name="amount"
          rules={[{ required: true, message: "Please enter an amount" }]}
        >
          <MyInputNumber
            min={1}
            prefix={currencyCode || "INR"}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) =>
              value
                ? parseFloat(
                    value.replace(
                      new RegExp(`\\${currencyCode}\\s?|,`, "g"),
                      ""
                    )
                  )
                : 0
            }
            style={{ width: "100%" }}
          />
        </Form.Item>

        {props.userData?.occasions && props.userData?.occasions.length ? (
          <Form.Item
            label="Occasion"
            name="occasionId"
            tooltip={{
              title: "Associate Your Expenses with an Occasion",
              icon: <InfoCircleOutlined />,
            }}
          >
            <MySelect placeholder="Select an occasion">
              {props.userData?.occasions.map((value: OccasionType, key) => {
                return (
                  <Option value={value.id} key={key}>
                    {value.occasion}
                  </Option>
                );
              })}
            </MySelect>
          </Form.Item>
        ) : (
          <>
            <Alert
              message="Associate Your Expenses with an Occasion!"
              type="info"
              showIcon
              description={
                <>
                  <Text strong>
                    Associate your expenses with an occasion. If you haven't
                    created one yet, click the link to get started!
                  </Text>
                  &nbsp;
                  <Link onClick={() => navigate("/mde-occasions")}>
                    Create Occasion
                  </Link>
                </>
              }
            />
            <br />
          </>
        )}

        <Form.Item
          label="Notes"
          name="note"
          rules={[
            {
              pattern: /^(?!\s*$).+/,
              message: "Note cannot be empty or just spaces",
            },
            {
              max: 30,
              message: "Note must be less than or equal to 30 characters",
            },
          ]}
        >
          <MyInput placeholder="Add any notes here" maxLength={30} />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              {selectedTransaction ? "Update" : "Submit"}
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </MyCard>
  );
};

export default ExpenseForm;
