import { Chart } from "react-google-charts";

interface PieChartComponentProps {
  pieData: (string | number)[][];
}

const options = {
  title: "Expense Activities By Categories",
  // backgroundColor: "#2C2C2C", // Chart background color
};

const PieChartComponent = (props: PieChartComponentProps) => {
  return (
    <Chart
      chartType="PieChart"
      data={props.pieData}
      width="99%"
      height="400px"
      options={options}
    />
  );
};

export default PieChartComponent;
