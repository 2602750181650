import React from "react";
import { InputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";

interface MyInputNumberProps extends InputNumberProps<number> {}

const MyInputNumber: React.FC<MyInputNumberProps> = (props) => {
  return <InputNumber {...props} />;
};

export default MyInputNumber;
