import {
  PlusCircleOutlined,
  DashboardOutlined,
  TransactionOutlined,
  AppstoreOutlined,
  SettingOutlined,
  LogoutOutlined,
  CustomerServiceOutlined,
  InfoCircleOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Avatar, Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import "./styles.scss";
import { useFirebase } from "../../context/Firebase";

const { Sider } = Layout;

interface SiderMenuProps {
  collapsed: boolean;
}

const SiderLayout = (props: SiderMenuProps) => {
  const { collapsed } = props;
  const navigate = useNavigate();
  const firebase = useFirebase();
  const location = useLocation();
  const { pathname } = location;

  const pathToKey: any = {
    "/mde-dashboard": "1",
    "/mde-add-expenses": "2",
    "/mde-transactions": "3",
    "/mde-categories": "4",
    "/mde-occasions": "5",
    "/mde-settings": "6",
    "/mde-contactus": "7",
    "/mde-about": "8",
  };

  const selectedKey = pathToKey[pathname] || "1";

  return (
    <>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="sider-logo">
          <Avatar src="/images/3-BR_512x512.png" size={80} />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]}
          items={[
            {
              key: "1",
              icon: <DashboardOutlined />,
              label: "Dashboard",
              onClick: () => {
                navigate("/mde-dashboard");
              },
            },
            {
              key: "2",
              icon: <PlusCircleOutlined />,
              label: "Add Expenses",
              onClick: () => {
                navigate("/mde-add-expenses");
              },
            },
            {
              key: "3",
              icon: <TransactionOutlined />,
              label: "Transactions",
              onClick: () => {
                navigate("/mde-transactions");
              },
            },
            {
              key: "4",
              icon: <AppstoreOutlined />,
              label: "Categories",
              onClick: () => {
                navigate("/mde-categories");
              },
            },
            {
              key: "5",
              icon: <CalendarOutlined />,
              label: "Occasions",
              onClick: () => {
                navigate("/mde-occasions");
              },
            },
            {
              key: "6",
              label: "Settings",
              icon: <SettingOutlined />,
              onClick: () => {
                navigate("/mde-settings");
              },
            },
            {
              key: "7",
              icon: <CustomerServiceOutlined />,
              label: "Contact Us",
              onClick: () => {
                navigate("/mde-contactus");
              },
            },
            {
              key: "8",
              icon: <InfoCircleOutlined />,
              label: "About MDE",
              onClick: () => {
                navigate("/mde-about");
              },
            },
            {
              key: "9",
              icon: <LogoutOutlined />,
              label: "Logout",
              onClick: () => {
                firebase?.logout();
                navigate("/");
              },
            },
          ]}
        />
      </Sider>
    </>
  );
};

export default SiderLayout;
