import React from "react";
import { Radio } from "antd";
import { RadioGroupProps } from "antd/lib/radio";

const { Group } = Radio;

interface MyRadioGroupProps extends RadioGroupProps {}

const MyRadioGroup: React.FC<MyRadioGroupProps> = ({ children, ...props }) => {
  return <Group {...props}>{children}</Group>;
};

export default MyRadioGroup;
