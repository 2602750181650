import React from "react";
import { Input } from "antd";
import { InputProps } from "antd/lib/input";

interface MyInputProps extends InputProps {}

const MyInput: React.FC<MyInputProps> = ({ ...props }) => {
  return <Input {...props} />;
};

export default MyInput;
