import React from "react";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";

const { Option } = Select;

interface MySelectProps<T> extends SelectProps<T> {}

const MySelect = <T extends any>({ children, ...props }: MySelectProps<T>) => {
  return <Select {...props}>{children}</Select>;
};

export default MySelect;
