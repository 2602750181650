import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Layout,
  Row,
  Switch,
  theme,
  Typography,
} from "antd";
import { useFirebase } from "../../context/Firebase";
import DownloadAppButton from "../downloadAppButton/DownloadAppButton";

const { Header } = Layout;
const { Title } = Typography;

interface HeaderLayoutProps {
  collapsed: boolean;
  toggleTheme: (val: boolean) => void;
  setCollapsed: (x: boolean) => void;
}

const HeaderLayout = (props: HeaderLayoutProps) => {
  const { collapsed, setCollapsed, toggleTheme } = props;
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const firebase = useFirebase();

  return (
    <>
      <Header style={{ padding: 0, background: colorBgContainer }}>
        <Row>
          <Col md={5} xs={6}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            {process.env.REACT_APP_GIT_TAG}
          </Col>
          <Col md={4}>
            <a
              href="https://youtu.be/JkJLYlr9sWA?si=frYqzRFkrAEvxpeR"
              target="_blank"
            >
              <Button danger icon={<YoutubeOutlined />}>
                How To Use
              </Button>
            </a>
          </Col>
          <Col md={5}>
            <DownloadAppButton />
          </Col>
          <Col md={4}>
            <Switch
              checkedChildren="Light 🌞"
              unCheckedChildren="Dark🌛"
              onChange={toggleTheme}
            />
          </Col>
          <Col md={5} xs={17}>
            <Title level={5} style={{ marginTop: "10%" }}>
              Welcome, {firebase?.userCollection?.name}
            </Title>
          </Col>
          <Col md={1} xs={1}>
            <Avatar
              src={
                firebase?.user?.photoURL
                  ? firebase?.user.photoURL
                  : "/images/male_avatar.png"
              }
            />
          </Col>
        </Row>
      </Header>
    </>
  );
};

export default HeaderLayout;
