import { Button, Col, Form, Row, Select, Spin, Tag } from "antd";
import { useEffect, useState } from "react";
import { useFirebase } from "../../context/Firebase";
import {
  CollectionType,
  CurrencyDataType,
  FirebaseContextType,
} from "../dtype/All";
import openNotification from "../openNotification/openNotification";
import MyCard from "../utility/myCard/MyCard";
import MySelect from "../utility/mySelect/MySelect";
import { CurrencyData } from "./CurrencyData";

const { Option } = Select;

const Currency = () => {
  const [form] = Form.useForm();
  const firebase: FirebaseContextType | null = useFirebase();
  const userId: string | undefined = firebase?.user?.uid;
  const [userData, setUserData] = useState<CollectionType>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userId) {
      setLoading(true);
      getUserCollection(userId);
    }
  }, [userId, firebase]);

  const getUserCollection = async (userId: string) => {
    firebase?.getData(userId)?.then((result: any) => {
      setUserData(result);
      form.setFieldsValue({ currency: result.currency });
    });
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    if (userId && userData) {
      setLoading(true);
      try {
        userData.currency = values.currency;
        await firebase?.saveData(userId, userData);
        await getUserCollection(userId);
        openNotification({
          type: "success",
          message: "Hurray!!!",
          description: "Successfully saved your currency",
        });
      } catch (error) {
        setLoading(false);
        openNotification({
          type: "error",
          message: "Something went wrong!!!",
          description: "Try relogin in",
        });
        console.error("Error adding document: ", error);
      }
    }
  };

  return (
    <MyCard title="Set Currency">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Currency"
          name="currency"
          rules={[{ required: true, message: "Please select currency!" }]}
          initialValue={userData?.currency}
        >
          <MySelect placeholder="Select a currency">
            {CurrencyData.map((data: CurrencyDataType, key) => {
              return (
                <Option value={data.code} key={key}>
                  <Row>
                    <Col span={4}>
                      <Tag color="gold">{data.code}</Tag>
                    </Col>
                    <Col span={20}>{data.name}</Col>
                  </Row>
                </Option>
              );
            })}
          </MySelect>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Spin spinning={loading} fullscreen />
    </MyCard>
  );
};

export default Currency;
