import { Button, Popconfirm, Space, Tag } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import MyCard from "../utility/myCard/MyCard";
import MyTable from "../utility/myTable/MyTable";
import { OccasionType } from "../dtype/All";

interface OccasionsTableProps {
  occasions: OccasionType[];
  handleDelete: (id: string) => void;
}

const OccasionsTable = (props: OccasionsTableProps) => {
  const { occasions, handleDelete } = props;

  const deleteData = (occasion: OccasionType) => {
    handleDelete(occasion.id);
  };

  const columns = [
    {
      title: "Occasion",
      dataIndex: "occasion",
      key: "occasion",
    },
    {
      title: "Occasion Date",
      dataIndex: "occasionDate",
      key: "occasionDate",
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, occasion: OccasionType) => (
        <Space size="middle">
          <Popconfirm
            title="Delete the occasion"
            description="Are you sure to delete this occasion?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteData(occasion)}
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <MyCard
      title="Categories"
      style={{
        width: 600,
        margin: "50px auto",
      }}
    >
      <MyTable columns={columns} dataSource={occasions} />
    </MyCard>
  );
};

export default OccasionsTable;
