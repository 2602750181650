import { Button, Popconfirm, Space, Tag } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import MyCard from "../utility/myCard/MyCard";
import MyTable from "../utility/myTable/MyTable";
import { useNavigate } from "react-router-dom";
import { OccasionMapType, TransactionType, TYPE_DATA } from "../dtype/All";
import { TYPE_COLOR_TAG } from "../designSystem/ElementColor";

interface ExpenseTableProps {
  todaysTransactions: TransactionType[];
  currencyCode: string;
  occasionMap: OccasionMapType;
  handleEdit: (key: string) => void;
  handleDelete: (key: string) => void;
}

const ExpenseTable = (props: ExpenseTableProps) => {
  const {
    todaysTransactions,
    currencyCode,
    occasionMap,
    handleEdit,
    handleDelete,
  } = props;
  const navigate = useNavigate();

  const editData = (key: string) => {
    handleEdit(key);
  };

  const deleteData = (key: string) => {
    handleDelete(key);
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type: string) => {
        let color =
          type === TYPE_DATA.Spending
            ? TYPE_COLOR_TAG.spending
            : type === TYPE_DATA.Income
            ? TYPE_COLOR_TAG.income
            : TYPE_COLOR_TAG.investment;
        return (
          <Tag color={color} key={type}>
            {type}
          </Tag>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: number) => `${currencyCode} ${text}`,
    },
    {
      title: "Occasion",
      dataIndex: "occasionId",
      key: "occasionId",
      render: (text: string) => occasionMap[text],
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: TransactionType) => (
        <Space size="middle">
          {/* <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => editData(record.id)}
          /> */}
          <Popconfirm
            title="Delete the transaction"
            description="Are you sure to delete this transaction?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDelete(record.id)}
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <MyCard
      title={`Today's Expenses`}
      extra={
        <a onClick={() => navigate("/mde-transactions")}>All Transactions</a>
      }
      style={{
        width: 600,
        margin: "50px auto",
      }}
    >
      <MyTable columns={columns} dataSource={todaysTransactions} />
    </MyCard>
  );
};

export default ExpenseTable;
