import { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import OccasionsForm from "./OccasionsForm";
import OccasionsTable from "./OccasionsTable";
import { Form } from "antd";
import { useFirebase } from "../../context/Firebase";
import {
  OccasionType,
  CollectionType,
  FirebaseContextType,
  TYPE_DATA,
  TYPE_DATA_TYPE,
  TransactionType,
} from "../dtype/All";
import openNotification from "../openNotification/openNotification";
import { uuidv4 } from "@firebase/util";
import { map as _map, sortBy as _sortBy } from "lodash";

const Occasions = () => {
  const [form] = Form.useForm();
  const [type, setType] = useState<TYPE_DATA_TYPE>(
    TYPE_DATA.Spending as TYPE_DATA_TYPE
  );
  const firebase: FirebaseContextType | null = useFirebase();
  const userId: string | undefined = firebase?.user?.uid;
  const [userData, setUserData] = useState<CollectionType>();
  const [occasionsList, setOccasionsList] = useState<OccasionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userId) {
      setLoading(true);
      getUserCollection(userId);
    }
  }, [userId, firebase]);

  useEffect(() => {
    prepareOccasionsListForTableView();
  }, [userData]);

  const prepareOccasionsListForTableView = () => {
    setOccasionsList(userData?.occasions || []);
  };

  const getUserCollection = (userId: string) => {
    firebase?.getData(userId)?.then((result: any) => {
      setUserData(result);
    });
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    if (!userId || !userData) {
      openNotification({
        type: "error",
        message: "Something went wrong!!!",
        description: "Try relogin in",
      });
      return;
    }

    const allOccasions: string[] = _map(userData?.occasions, "occasion");
    if (allOccasions.includes(values.occasion)) {
      openNotification({
        type: "error",
        message: "Duplicate occasion!!!",
        description: `Occasion name with ${values.occasion} already exist.`,
      });
      return;
    }

    setLoading(true);

    try {
      const formattedData = {
        ...values,
        occasionDate: values.occasionDate.format("YYYY-MM-DD"),
        id: uuidv4(),
      };

      if (!formattedData?.note) {
        delete formattedData.note;
      }

      console.log(userData);

      if (!userData.occasions) {
        userData["occasions"] = [];
      }

      userData.occasions.push(formattedData);

      await firebase?.saveData(userId, userData);
      await getUserCollection(userId);
      resetForm();
      openNotification({
        type: "success",
        message: "Hurray!!!",
        description: "Successfully saved your occasion",
      });
    } catch (error) {
      setLoading(false);
      openNotification({
        type: "error",
        message: "Something went wrong!!!",
        description: "Try relogin in",
      });
      console.error("Error adding document: ", error);
    }
  };

  const resetForm = () => {
    form.resetFields();
  };

  const onTypeChange = (e: any) => {
    setType(e.target.value);
  };

  const handleDelete = async (id: string) => {
    if (userData?.occasions && userId) {
      let arr = userData.occasions.filter((item) => item.id !== id);

      userData.transactions.map((transaction: TransactionType, index) => {
        if (transaction.occasionId && transaction.occasionId === id) {
          const obj = { ...transaction };
          delete obj.occasionId;

          userData.transactions[index] = obj;
        }
      });

      userData.occasions = arr;
      _sortBy(userData.occasions, ["occasionDate"]);
      await firebase?.saveData(userId, userData);
      await getUserCollection(userId);

      openNotification({
        type: "success",
        message: "Occasion deleted!!!",
      });
    }
  };

  return (
    <Row>
      <Col md={10}>
        <OccasionsForm
          form={form}
          userData={userData}
          onTypeChange={onTypeChange}
          onFinish={onFinish}
          onReset={resetForm}
        />
      </Col>
      <Col md={14}>
        <OccasionsTable occasions={occasionsList} handleDelete={handleDelete} />
      </Col>
      <Spin spinning={loading} fullscreen />
    </Row>
  );
};

export default Occasions;
