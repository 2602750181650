import React, { useState } from "react";
import { Form, Button, Row, Col, Spin } from "antd";
import { UserOutlined, MailOutlined, LockOutlined } from "@ant-design/icons";
import MyCard from "../utility/myCard/MyCard";
import MyInput from "../utility/myInput/MyInput";
import { useNavigate } from "react-router-dom";
import { useFirebase } from "../../context/Firebase";
import { AuthUser, FirebaseContextType } from "../dtype/All";

const { Item } = Form;

const SignUp: React.FC = () => {
  const firebase: FirebaseContextType | null = useFirebase();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (values: any) => {
    setLoading(true);
    const payload: AuthUser = {
      name: values.name,
      email: values.email,
      password: values.password,
    };

    const resp = firebase?.signupUsingEmailPassword(payload);
    form.resetFields();
    if (resp) setLoading(false);
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
    },
    string: {
      range: "${label} must be between ${min} and ${max} characters",
      min: "${label} must be at least ${min} characters",
    },
    pattern: {
      mismatch: "${label} does not match the pattern",
    },
  };

  const passwordValidator = (_: any, value: string) => {
    if (!value) {
      return Promise.reject("Password is required");
    }
    if (value.length < 6) {
      return Promise.reject("Password must be at least 6 characters");
    }
    return Promise.resolve();
  };

  const confirmPasswordValidator = ({ getFieldValue }: any) => ({
    validator(_: any, value: string) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject("Passwords do not match");
    },
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <img src="/images/1-BR.png" alt="Logo" style={{ marginBottom: 20 }} />
      <MyCard title="Sign Up" style={{ width: 400 }}>
        <Form
          form={form}
          name="sign_up"
          onFinish={onFinish}
          validateMessages={validateMessages}
          layout="vertical"
        >
          <Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                min: 2,
                max: 30,
                message: "Name should be between 2 and 30 characters",
              },
              {
                pattern: /^(?!\s*$).+/,
                message: "Name cannot be empty or just spaces",
              },
            ]}
          >
            <MyInput prefix={<UserOutlined />} placeholder="Enter your name" />
          </Item>
          <Item
            name="email"
            label="Email"
            rules={[{ type: "email", required: true }]}
          >
            <MyInput prefix={<MailOutlined />} placeholder="Enter your email" />
          </Item>
          <Item
            name="password"
            label="Password"
            rules={[{ required: true, validator: passwordValidator }]}
          >
            <MyInput
              type="password"
              prefix={<LockOutlined />}
              placeholder="Enter your password"
            />
          </Item>
          <Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[{ required: true }, confirmPasswordValidator]}
          >
            <MyInput
              type="password"
              prefix={<LockOutlined />}
              placeholder="Confirm your password"
            />
          </Item>
          <Item>
            <Button type="primary" htmlType="submit" block>
              Sign Up
            </Button>
          </Item>
        </Form>
        <Row>
          <Col span={12}>
            <Button type="link" onClick={() => navigate("/")}>
              Signin Here
            </Button>
          </Col>
          <Col span={12}>
            <Button type="link" onClick={() => navigate("/reset-passowrd")}>
              Reset Password
            </Button>
          </Col>
        </Row>
      </MyCard>
      <Spin spinning={loading} fullscreen />
    </div>
  );
};

export default SignUp;
