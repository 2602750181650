import React, { useEffect } from "react";
import MainLayout from "./components/layouts/MainLayout";
import { Route, Routes } from "react-router-dom";
import Error from "./components/error/Error";
import { useFirebase } from "./context/Firebase";
import SignUp from "./components/auth/SignUp";
import SignIn from "./components/auth/SignIn";
import ResetPassword from "./components/auth/ResetPassword";

const App: React.FC = () => {
  useEffect(() => {
    const agent = navigator.userAgent.toLowerCase();
    const isMobile = /mobile|android|iphone|ipad|tablet/.test(agent);
    if (isMobile) {
      window.location.href = "https://mobile.mydailyexpenses.com/";
    }
  }, []);
  const firebase = useFirebase();
  return (
    <>
      {firebase?.user && firebase?.user.emailVerified ? (
        <MainLayout />
      ) : (
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reset-passowrd" element={<ResetPassword />} />
          <Route path="*" element={<Error />} />
        </Routes>
      )}
    </>
  );
};

export default App;
