import React from "react";
import { DatePicker } from "antd";
import { DatePickerProps } from "antd/lib/date-picker";

interface MyDatePickerProps extends DatePickerProps {}

const MyDatePicker: React.FC<MyDatePickerProps> = ({ ...props }) => {
  return <DatePicker {...props} />;
};

export default MyDatePicker;
