import { createContext, useContext, useEffect, useState } from "react";
import { ThemeType } from "../components/dtype/All";

const ThemeContext = createContext<ThemeType | null>(null);

export const useMyTheme = () => useContext(ThemeContext);

interface ThemeProviderProps {
  children: any;
}

export const MyThemeProvider = (props: ThemeProviderProps) => {
  const [theme, setTheme] = useState("light");
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
};
