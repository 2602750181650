import { useState } from "react";
import { Button, Tag } from "antd";
import MyModal from "../utility/myModal/MyModal";
import MyInput from "../utility/myInput/MyInput";
import openNotification from "../openNotification/openNotification";
import MyCard from "../utility/myCard/MyCard";
import { useFirebase } from "../../context/Firebase";

const DeleteAccount = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [value, setValue] = useState<string>();
  const firebase = useFirebase();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (value === "DELETE") {
      firebase?.deleteAccount();
    } else {
      openNotification({
        type: "error",
        message: "Wrong Entry!!!",
        description: "Please enter correct text.",
      });
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handaleChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <MyCard title="Delete Account">
      <Button
        onClick={showModal}
        style={{ width: "100%" }}
        type="primary"
        danger
      >
        Delete Account
      </Button>
      <MyModal
        title="Confirm Deletion"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={(_, _1) => (
          <>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" danger onClick={handleOk}>
              Yes, Delete
            </Button>
          </>
        )}
      >
        <p>
          Are you sure you want to delete your account? This action cannot be
          undone. If Yes, please write <Tag color="red">DELETE</Tag> in below
          text field.
        </p>
        <MyInput onChange={handaleChange} />
      </MyModal>
    </MyCard>
  );
};

export default DeleteAccount;
