import { useState } from "react";
import { ConfigProvider, Layout, theme as antdTheme } from "antd";
import SiderLayout from "./SiderLayout";
import HeaderLayout from "./HeaderLayout";
import { Route, Routes } from "react-router-dom";
import Expenses from "../expenses/Expenses";
import Dashboard from "../dashboard/Dashboard";
import Transactions from "../transactions/Transactions";
import Category from "../category/Category";
import Settings from "../settings/Settings";
import { Footer } from "antd/es/layout/layout";
import Error from "../error/Error";
import { useMyTheme } from "../../context/Theme";
import FooterLayout from "./FooterLayout";
import ContactUs from "../contactUs/ContactUs";
import AboutApp from "../aboutApp/AboutApp";
import Occasions from "../occasions/Occasions";

const { Content } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { borderRadiusLG },
  } = antdTheme.useToken();
  const myTheme = useMyTheme();

  const toggleTheme = (checked: boolean) => {
    if (checked) {
      myTheme?.setTheme("dark");
    } else {
      myTheme?.setTheme("light");
    }
  };

  return (
    <ConfigProvider
      theme={{
        algorithm:
          myTheme?.theme === "dark"
            ? antdTheme.darkAlgorithm
            : antdTheme.defaultAlgorithm,
      }}
    >
      <Layout>
        <SiderLayout collapsed={collapsed} />
        <Layout>
          <HeaderLayout
            setCollapsed={setCollapsed}
            collapsed={collapsed}
            toggleTheme={toggleTheme}
          />
          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 750,
              borderRadius: borderRadiusLG,
            }}
          >
            <Routes>
              <Route index element={<Dashboard />} />
              <Route path="/mde-add-expenses" element={<Expenses />} />
              <Route path="/mde-dashboard" element={<Dashboard />} />
              <Route path="/mde-transactions" element={<Transactions />} />
              <Route path="/mde-categories" element={<Category />} />
              <Route path="/mde-occasions" element={<Occasions />} />
              <Route path="/mde-settings" element={<Settings />} />
              <Route path="/mde-contactus" element={<ContactUs />} />
              <Route path="/mde-about" element={<AboutApp />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </Content>
          <FooterLayout />
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default MainLayout;
