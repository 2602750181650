import { Col, Row } from "antd";
import Currency from "../currency/Currency";
import DeleteAccount from "../deleteAccount/DeleteAccount";
import Language from "../language/Language";

const Settings = () => {
  return (
    <>
      <Row>
        <Col md={7} offset={1}>
          <Currency />
        </Col>
        <Col md={7} offset={1}>
          <Language />
        </Col>
        <Col md={7} offset={1}>
          <DeleteAccount />
        </Col>
      </Row>
    </>
  );
};

export default Settings;
