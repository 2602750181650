import React, { useState } from "react";
import { Select, DatePicker, Button, Form, Space } from "antd";
import { SelectValue } from "antd/lib/select";
import MySelect from "../utility/mySelect/MySelect";
import MyDatePicker from "../utility/myDatePicker/MyDatePicker";
import { FILTER_OPTIONS_LIST, FILTER_OPTION_KEY } from "./FiterOptions";
import { OccasionType } from "../dtype/All";

const { Option } = Select;
const { RangePicker } = DatePicker;

interface FilterComponentProps {
  form: any;
  occasionList: OccasionType[];
  onFinish: (filterType: string, dateRange?: [string, string]) => void;
  onReset: () => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  form,
  occasionList,
  onFinish,
  onReset,
}) => {
  const [filterType, setFilterType] = useState<string>("all");

  const handleFilterTypeChange = (value: SelectValue) => {
    setFilterType(value as string);
  };

  const onClear = () => {
    setFilterType("");
    onReset();
  };

  const renderFilters = () => {
    switch (filterType) {
      case FILTER_OPTION_KEY.yearly:
        return <MyDatePicker picker="year" style={{ width: 300 }} />;
      case FILTER_OPTION_KEY.yearlyMonthly:
        return <DatePicker.MonthPicker style={{ width: 300 }} />;
      case FILTER_OPTION_KEY.range:
        return <RangePicker style={{ width: 300 }} />;
      case FILTER_OPTION_KEY.occasion:
        return (
          <MySelect placeholder="Select an occasion">
            {occasionList.map((value: OccasionType, key) => {
              return (
                <Option value={value.id} key={key}>
                  {value.occasion}
                </Option>
              );
            })}
          </MySelect>
        );
      default:
        return null;
    }
  };

  return (
    <Form form={form} layout="inline" onFinish={onFinish}>
      <Form.Item name="filter" initialValue="all">
        <MySelect
          style={{ width: 300 }}
          placeholder="Select Filter"
          value={filterType}
          onChange={handleFilterTypeChange}
        >
          {FILTER_OPTIONS_LIST.map((data, key) => {
            return (
              <Option value={data.key} key={key}>
                {data.label}
              </Option>
            );
          })}
        </MySelect>
      </Form.Item>
      <Form.Item name="selectedFilter">{renderFilters()}</Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Filter
          </Button>
          <Button htmlType="button" onClick={onClear}>
            Clear
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default FilterComponent;
