import { Button, Popconfirm, Space, Tag } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import MyCard from "../utility/myCard/MyCard";
import MyTable from "../utility/myTable/MyTable";
import { CategoryType, TYPE_DATA } from "../dtype/All";
import { TYPE_COLOR_TAG } from "../designSystem/ElementColor";

interface CategoryTableProps {
  categories: CategoryType[];
  handleDelete: (category: CategoryType) => void;
}

const CategoryTable = (props: CategoryTableProps) => {
  const { categories, handleDelete } = props;

  const deleteData = (category: CategoryType) => {
    handleDelete(category);
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type: string) => {
        let color =
          type === TYPE_DATA.Spending
            ? TYPE_COLOR_TAG.spending
            : type === TYPE_DATA.Income
            ? TYPE_COLOR_TAG.income
            : TYPE_COLOR_TAG.investment;
        return (
          <Tag color={color} key={type}>
            {type}
          </Tag>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, category: CategoryType) => (
        <Space size="middle">
          <Popconfirm
            title="Delete the category"
            description="Are you sure to delete this category?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteData(category)}
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <MyCard
      title="Categories"
      style={{
        width: 600,
        margin: "50px auto",
      }}
    >
      <MyTable columns={columns} dataSource={categories} />
    </MyCard>
  );
};

export default CategoryTable;
