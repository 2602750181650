import React, { useState } from "react";
import { Table } from "antd";
import { TableProps } from "antd/lib/table";

interface MyTableProps<T> extends TableProps<T> {
  // Extend TableProps with any additional props if needed
}

const MyTable = <T extends object>({ ...props }: MyTableProps<T>) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const handleTableChange = (pagination: any) => {
    console.log();
    setPagination(pagination);
  };

  return (
    <Table {...props} pagination={pagination} onChange={handleTableChange} />
  );
};

export default MyTable;
