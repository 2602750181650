import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const DownloadAppButton = () => {
  return (
    <a href="https://backup.mydailyexpenses.com/" target="_blank">
      <Button type="primary" shape="round" icon={<DownloadOutlined />}>
        Download Android App
      </Button>
    </a>
  );
};

export default DownloadAppButton;
