import { CurrencyDataType } from "../dtype/All";

export const CurrencyData: CurrencyDataType[] = [
  {
    code: "AUD",
    name: "Australian Dollar",
  },
  {
    code: "BDT",
    name: "Bangladeshi Taka",
  },
  {
    code: "BRL",
    name: "Brazilian Real",
  },
  {
    code: "CAD",
    name: "Canadian Dollar",
  },
  {
    code: "CHF",
    name: "Swiss Franc",
  },
  {
    code: "CNY",
    name: "Chinese Yuan",
  },
  {
    code: "EUR",
    name: "Euro",
  },
  {
    code: "GBP",
    name: "British Pound Sterling",
  },
  {
    code: "INR",
    name: "Indian Rupee",
  },
  {
    code: "JPY",
    name: "Japanese Yen",
  },
  {
    code: "LKR",
    name: "Sri Lankan Rupee",
  },
  {
    code: "MXN",
    name: "Mexican Peso",
  },
  {
    code: "NOK",
    name: "Norwegian Krone",
  },
  {
    code: "NPR",
    name: "Nepalese Rupee",
  },
  {
    code: "NZD",
    name: "New Zealand Dollar",
  },
  {
    code: "PKR",
    name: "Pakistani Rupee",
  },
  {
    code: "RUB",
    name: "Russian Ruble",
  },
  {
    code: "SEK",
    name: "Swedish Krona",
  },
  {
    code: "SGD",
    name: "Singapore Dollar",
  },
  {
    code: "USD",
    name: "United States Dollar",
  },
  {
    code: "ZAR",
    name: "South African Rand",
  },
];
