import React, { useState } from "react";
import { Form, Button, Row, Col, Spin } from "antd";
import { MailOutlined } from "@ant-design/icons";
import MyCard from "../utility/myCard/MyCard";
import MyInput from "../utility/myInput/MyInput";
import { useNavigate } from "react-router-dom";
import { FirebaseContextType } from "../dtype/All";
import { useFirebase } from "../../context/Firebase";

const { Item } = Form;

const ResetPassword: React.FC = () => {
  const firebase: FirebaseContextType | null = useFirebase();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (values: any) => {
    setLoading(true);
    const resp = firebase?.resetPassword(values.email);
    form.resetFields();
    if (resp) setLoading(false);
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <img src="/images/1-BR.png" alt="Logo" style={{ margin: 20 }} />
      <MyCard title="Forgot Password" style={{ width: 400 }}>
        <Form
          form={form}
          name="forgot_password"
          onFinish={onFinish}
          validateMessages={validateMessages}
          layout="vertical"
        >
          <Item
            name="email"
            label="Email"
            rules={[{ type: "email", required: true }]}
          >
            <MyInput prefix={<MailOutlined />} placeholder="Enter your email" />
          </Item>
          <Item>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Item>
        </Form>
        <Row>
          <Col span={24}>
            <Button type="link" onClick={() => navigate("/")}>
              Signin Here
            </Button>
          </Col>
        </Row>
      </MyCard>
      <Spin spinning={loading} fullscreen />
    </div>
  );
};

export default ResetPassword;
